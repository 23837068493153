import React from 'react'
import { useColorMode } from 'theme-ui'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '@components/Layout'
import {
  Section,
  NarrowSection,
  SectionStyler,
  SectionTopHeader,
  SectionWrapper,
  SectionContainer,
} from '@components/Section'
import Seo from '@components/SEO'

import Vector from '@components/Vector'

import {
  SectionParagraph,
  SectionHeading,
  SectionHeader,
} from '@components/Section/SectionHeader'

import { CallToButton } from '@components/Button'

const AboutPage = (data) => {
  const [colorMode] = useColorMode()
  const teamSectionBgColor = `${(p) => p.theme.colors.primaryContrast}`

  return (
    <Layout>
      <Seo
        image="/images/og/about.png"
        url="/about"
        title="About Us"
        description="At the core, we're a group of passionate marketing professionals that want to help you take your business to the next level while leveraging the best marketing solutions in the industry."
      />
      <SectionTopHeader>
        <SectionWrapper>
          <SectionHeader>About Us</SectionHeader>
          <SectionHeading.h1>We are Auotiv</SectionHeading.h1>
          <SectionParagraph>
            At the core, we're a group of passionate marketing professionals
            that want to help you take your business to the next level while
            leveraging the best marketing solutions in the industry.
          </SectionParagraph>
          <CallToButton
            to="/about#team"
            title="Work with us"
            aria-label="Work with us"
          >
            Meet the Team
          </CallToButton>
        </SectionWrapper>
        <Vector
          name="arabica-1"
          alt="A hero image illustration depicting a creature looking through a telescope into space"
          horizontalInvert
        />
      </SectionTopHeader>

      <NarrowSection>
        <div style={{ textAlign: 'center' }}>
          <SectionHeading.h3>
            Client-focused, forward-thinking people
          </SectionHeading.h3>
          <SectionContainer center>
            <SectionParagraph>
              We want to be the strategic marketing partners that you can trust
              to grow your company.
            </SectionParagraph>
          </SectionContainer>
        </div>
      </NarrowSection>

      <NarrowSection id="team" medium>
        <SectionStyler color={{ teamSectionBgColor }}>
          <Section style={{ textAlign: 'center' }}>
            <SectionHeader>We are</SectionHeader>
            <SectionHeading.h3>Auotiv Team</SectionHeading.h3>
            {/* <SectionContainer center>
              <SectionParagraph>Working from all around the world, joining together create something incredible.</SectionParagraph>
            </SectionContainer> */}
          </Section>
          <Profiles />
        </SectionStyler>
      </NarrowSection>
    </Layout>
  )
}

const Profiles = () => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  const data = useStaticQuery(graphql`
    query GetProfileImages {
      profileImages: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(svg)/" }
          relativeDirectory: { eq: "team" }
        }
      ) {
        totalCount
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 250)
            }
            extension
            publicURL
          }
        }
      }
    }
  `)

  const profileImageMap = {}
  data.profileImages.edges.forEach((edge) => {
    profileImageMap[edge.node.name] = edge.node
  })

  // @todo: convert into team folder/member.yaml? but need to have an order
  const team = [
    {
      name: 'Derek Liang',
      title: 'Owner',
      profileImageData:
        profileImageMap['derek-liang'].childImageSharp.gatsbyImageData,
      alt: 'This is Derek Liang, a team member of Auotiv.',
    },
  ]

  const teamProfiles = team.map((member) => {
    return (
      <MemberSection>
        <GatsbyImage
          alt={member.alt}
          image={member.profileImageData}
        ></GatsbyImage>
        <MemberDetails isDark={isDark}>
          <div>{member.name}</div>
          <p>{member.title}</p>
        </MemberDetails>
      </MemberSection>
    )
  })

  return <TeamGrid>{teamProfiles}</TeamGrid>
}

const MemberDetails = styled.div`
  margin-top: 20px;
  width: 100%;
  color: ${(p) => (p.isDark ? '#fff' : '#000')};

  div {
    font-family: ${(p) => p.theme.fonts.serif};
    font-weight: 400;
    font-size: 18px;
  }

  p {
    font-family: ${(p) => p.theme.fonts.serif};
    font-weight: 200;
    font-size: 14px;
  }
`

const MemberSection = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  min-height: 240px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  img {
    border-radius: 50%;
  }
`

const TeamGrid = styled.div`
  display: grid;
  // grid-template-columns: repeat(auto-fill,minmax(calc(10vw + 4.5rem),4fr));
  grid-template-columns: 1fr;
  max-width: 68.2rem;
  margin: 3.1rem auto 0 auto;
`

export default AboutPage
